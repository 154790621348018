import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTransition, UseTransitionProps } from 'react-spring';

import { Box } from '../box/box';

import { Notification } from './notification';
import * as styles from './toast-rack.style';
import { ToastContent } from './types';
import { getDismissalTimeFromCharacterLength } from './utilities';

interface PieceOfToast {
  key: string;
  children: ToastContent;
}

interface ToastRackProviderProps {
  children: ReactNode;
}
interface Context {
  toasts: PieceOfToast[];
  /* NOTE: the ReactNode cannot use <FormattedMessage />
   * because the onlyText function cannot extract the text.
   **/
  addToast: (toast: ToastContent) => void;
}

const ANIM = {
  OUT: { transform: 'translate3d(0,20px,0)', opacity: 0 },
  IN: {
    transform: 'translate3d(0,0px,0)',
    opacity: 1,
  },
};

const springConfig: UseTransitionProps['config'] = (
  _item,
  _index,
  transitionPhase,
) =>
  transitionPhase !== 'leave'
    ? {
        mass: 1,
        tension: 150,
        friction: 18,
        clamp: true,
        velocity: 0.001, // seconds
      }
    : {
        mass: 2,
        tension: 180,
        friction: 5,
        clamp: true,
        velocity: 0.001, // seconds
      };

const toastRackContext = createContext<Context | undefined>(undefined);

const { Provider } = toastRackContext;

/**
 * Retrieve the addToast function and list of toasts from context.
 */
export const useToasty = (): Context => {
  const toastValues = useContext(toastRackContext);

  if (!toastValues) {
    throw new Error(
      'No Toast Rack context, make sure you have wrapped in ToastRackProvider',
    );
  }

  return toastValues;
};

/**
 * Keeps an array of toast notifications in state, arranged oldest to newest.
 * When a new item is added, the character count is calculated and used to
 * set a timeout for auto-dismissal
 */
const useToastRack = () => {
  const [toasts, setToasts] = useState<PieceOfToast[]>([]);

  return {
    toasts,
    addToast: useCallback(
      (children: ToastContent) => {
        const key = crypto.randomUUID();

        setToasts((currentToasts) => [...currentToasts, { key, children }]);

        window.setTimeout(() => {
          setToasts((currentToasts) =>
            currentToasts.filter((toast) => toast.key !== key),
          );
        }, getDismissalTimeFromCharacterLength(children));
      },
      [setToasts],
    ),
  };
};

export const ToastRackProvider = ({ children }: ToastRackProviderProps) => {
  const { toasts, addToast } = useToastRack();

  const transitions = useTransition(toasts, {
    config: springConfig,
    from: ANIM.OUT,
    enter: ANIM.IN,
    leave: ANIM.OUT,
  });

  return (
    <Provider value={{ toasts, addToast }}>
      {children}
      <Box
        css={styles.toastRack}
        spacing="basePos1"
        alignContent={{ x: 'center', y: 'bottom' }}
        padding={{ bottom: 'basePos6' }}
      >
        {transitions((style, { children }) => (
          <Notification style={style}>{children}</Notification>
        ))}
      </Box>
    </Provider>
  );
};
