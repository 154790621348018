import { useMemo } from 'react';
import { Theme } from '@emotion/react';

import { ResponsiveProps, Spacing, Style } from '../../themes/types';
import { isResponsive, responsivePropsToStyles } from '../../themes/utilities';

import { gridItemStyles, GridsItemProps } from '../grid/grid-item.styles';
import * as styles from './use-box.styles';

interface XAxisNotLeftRight {
  x: Spacing;
  left?: never;
  right?: never;
}

type LeftRightNotXAxis = Partial<{
  left: Spacing;
  right: Spacing;
  x: never;
}>;

interface YAxisNotTopBottom {
  y: Spacing;
  top?: never;
  bottom?: never;
}

type TopBottomNotYAxis = Partial<{
  top: Spacing;
  bottom: Spacing;
  y: never;
}>;

type SpacingObject = (XAxisNotLeftRight | LeftRightNotXAxis) &
  (YAxisNotTopBottom | TopBottomNotYAxis);

export type Padding = Spacing | SpacingObject;

/**
 * Align items inside the box according to { x: X, y: Y }, e.g. { x: 'left', y: 'top' }
 * will align items to the left on the X axis and top of the Y axis.
 *
 * `full` will spread items along the axis with space between them.
 */
interface AlignContent {
  x?: keyof typeof styles.stackXAlignment.row;
  y?: keyof typeof styles.stackYAlignment.row;
}

type Display =
  | {
      // this variant is mainly meant for elements like <p /> or <span /> when we want to keep as block or inline.
      variant: 'initial';
      alignContent?: never;
      stack?: never;
      spacing?: never;
    }
  | {
      variant?: 'flex';
      /**
       * The direction of items within a (flex) box.
       */
      stack?: keyof typeof styles.stack;
      alignContent?: AlignContent;
      spacing?: Spacing;
    }
  | {
      variant: 'grid';
      stack?: never;
      alignContent?: AlignContent;
      spacing?: Spacing;
    };

export type UseBoxStyleProps = Display & {
  color?: styles.Color;
  border?: styles.Palette;
  background?: styles.Color;
  /**
   * Spacing around items inside the box.
   * Can be a single value: 'basePos3',
   * an array of two values: ['base','baseNeg1']
   * or a responsive object: {xs: 'base', m: ['basePos1','base]}
   */
  padding?: ResponsiveProps<Padding> | Padding;
  /**
   * Gives box a rounded appearance.
   */
  rounded?: boolean;
  /**
   * Give box a 'touch area' that expands beyond the box dimensions
   * without affecting layout.
   */
  touchArea?: boolean;
  /**
   * Allow the box to fill available space of the container
   */
  expand?: boolean;
  /**
   * hide contents without the box;
   */
  constrain?: boolean;
  size?: Partial<
    Record<'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight', Spacing>
  >;
  gridItem?: GridsItemProps;
};

type PaddingDirections = 'left' | 'right' | 'top' | 'bottom';

/**
 * matches up two parameters - one an object of padding props, and the other
 * an object of padding SerializedStyles to be used by the Emotion css prop
 */
const matchPaddingPropsWithStyles = (
  props: Partial<Record<PaddingDirections | 'x' | 'y', Spacing>>,
  style: Record<PaddingDirections, Style<Spacing>>,
) => {
  const left = props.left || props.x;
  const right = props.right || props.x;
  const top = props.top || props.y;
  const bottom = props.bottom || props.y;

  return [
    left && style.left[left],
    right && style.right[right],
    top && style.top[top],
    bottom && style.bottom[bottom],
  ];
};

/**
 * gets serialized styles from padding props.
 */
export const paddingPropsToStyles =
  (paddingProps: Padding | ResponsiveProps<Padding>) => (theme: Theme) => {
    if (!isResponsive(paddingProps)) {
      return typeof paddingProps === 'string'
        ? styles.padding(theme)[paddingProps]
        : matchPaddingPropsWithStyles(paddingProps, {
            left: styles.paddingLeft(theme),
            right: styles.paddingRight(theme),
            top: styles.paddingTop(theme),
            bottom: styles.paddingBottom(theme),
          });
    }

    return responsivePropsToStyles(paddingProps, ([breakpoint, padding]) =>
      typeof padding === 'string'
        ? styles.paddingResponsive(theme)[breakpoint][padding]
        : matchPaddingPropsWithStyles(padding, {
            left: styles.paddingLeftResponsive(theme)[breakpoint],
            right: styles.paddingRightResponsive(theme)[breakpoint],
            top: styles.paddingTopResponsive(theme)[breakpoint],
            bottom: styles.paddingBottomResponsive(theme)[breakpoint],
          }),
    );
  };

export const useBoxStyles = ({
  variant = 'flex',
  stack = 'column',
  alignContent,
  spacing,
  padding,
  rounded = false,
  touchArea = false,
  expand = false,
  color,
  background,
  size,
  constrain = false,
  border,
  gridItem,
}: UseBoxStyleProps) => {
  // We encode objects to a string here so that useMemo can do
  // an equality check on the values, then decode inside useMemo.
  const stringifiedObjectValues = JSON.stringify({
    alignContent,
    padding,
    color,
    size,
    gridItem,
  });

  return useMemo(() => {
    const { alignContent, padding, color, size, gridItem } = JSON.parse(
      stringifiedObjectValues,
    ) as Pick<
      UseBoxStyleProps,
      'alignContent' | 'padding' | 'color' | 'size' | 'gridItem'
    >;

    return [
      background && styles.colorPropStyles(background, 'background'),
      color && styles.colorPropStyles(color, 'color'),
      border && styles.border(border),
      size && styles.size(size),
      constrain && styles.constrain,
      gridItem && gridItemStyles(gridItem),
      variant !== 'initial' && [
        styles.variant[variant],
        alignContent?.x && styles.stackXAlignment[stack][alignContent.x],
        alignContent?.y && styles.stackYAlignment[stack][alignContent.y],
        spacing && styles.spacing(spacing),
      ],
      variant === 'flex' && styles.stack[stack],
      padding && paddingPropsToStyles(padding),
      touchArea && styles.touchArea,
      rounded && styles.borderRadius,
      expand && styles.expand,
    ];
  }, [
    border,
    background,
    constrain,
    variant,
    stack,
    spacing,
    rounded,
    touchArea,
    expand,
    stringifiedObjectValues,
  ]);
};
