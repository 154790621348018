import { css } from '@emotion/react';

import { Breakpoint, ThemeTokens, TypographyVariant } from '../../themes/types';
import {
  isResponsive,
  responsiveStyle,
  themeifyStyle,
} from '../../themes/utilities';

export const text = themeifyStyle(({ typography }) => ({
  fontFamily: typography.fontFamily,
  margin: 0,
}));

export const textTransform = {
  uppercase: css({
    textTransform: 'uppercase',
  }),
  lowercase: css({
    textTransform: 'lowercase',
  }),
  capitalize: css({
    textTransform: 'capitalize',
  }),
};

export const align = {
  left: css({
    textAlign: 'left',
  }),
  center: css({
    textAlign: 'center',
  }),
  right: css({
    textAlign: 'right',
  }),
};

export const inherit = css({
  fontSize: 'inherit',
});

export const variant = (
  variant: keyof ThemeTokens['typography']['variant'] | 'inherit',
) =>
  themeifyStyle((theme) => {
    if (variant === 'inherit') {
      return { font: 'inherit' };
    }

    const { typography } = theme;

    const {
      fontSize,
      lineHeight,
      fontWeight,
      textTransform,
    }: TypographyVariant = typography.variant[variant];

    const staticStyles = {
      lineHeight: typography.lineHeight[lineHeight],
      fontWeight: typography.fontWeight[fontWeight],
      textTransform,
    };

    if (isResponsive(fontSize)) {
      return {
        ...staticStyles,
        ...responsiveStyle(
          // responsiveStyle accepts an array in the shape of
          // [{breakpoint: 'xl', styles}]
          (Object.entries(fontSize) as Array<[Breakpoint, number]>).map(
            ([breakpoint, fontSize]) => ({
              breakpoint,
              styles: { fontSize },
            }),
          ),
          theme,
        ),
      };
    }

    return {
      ...staticStyles,
      fontSize,
    };
  });

export const truncate = css({
  whiteSpace: 'nowrap',
  overflowX: 'clip',
  textOverflow: 'ellipsis',
  // Suggest reviewing if we still need fallback end of 2023 onwards.
  '@supports not (overflow: clip)': {
    overflowX: 'hidden',
  },
});
