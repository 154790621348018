import { ElementType, ReactNode } from 'react';

import { useGridStyles, useGridStylesProps } from './use-grid-styles';
export interface GridProps extends useGridStylesProps {
  Component?: ElementType;
  className?: string;
  children: ReactNode;
}

/**
 * Provides the outer element for a Grid layout with styling props.
 * For custom positioning of child elements there is a gridItemStyles function
 * to complement this component
 */
const Grid = ({
  children,
  className,
  fullHeight,
  spacing,
  layout,
  Component = 'div',
  gridItem,
  ...restProps
}: GridProps) => {
  const styles = useGridStyles({ fullHeight, spacing, layout, gridItem });

  return (
    <Component className={className} css={styles} {...restProps}>
      {children}
    </Component>
  );
};

export { Grid, useGridStyles };
