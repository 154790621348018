import {
  Context,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useStorage } from '../../hooks/use-storage';

type Headers = RequestInit['headers'] | Promise<RequestInit['headers']>;

interface SteamhammerContext<Data> {
  getHeaders: () => Headers;
  setStorage: (data: Data) => void;
  storage: Partial<Data> | undefined;
}

export interface SteamhammerProviderProps<Data> {
  children: ReactNode;
  defaultOptions?: DefaultOptions;
  /**
   * Required for persistent storage of data
   */
  localStorageId?: string;
  /**
   * function which is passed onto any steamhammer fetch calls and
   * gives access to the current storage state as a parameter.
   */
  getHeaders: (storage?: Partial<Data>) => Headers;
}

const SteamhammerContext = createContext<SteamhammerContext<
  Record<string, string>
> | null>(null);

export const useSteamHammer = <Data extends Record<string, string>>() => {
  const context = useContext(SteamhammerContext);
  /* istanbul ignore next */
  if (context === null) {
    throw new Error('useSteamHammer must be used within a SteamhammerProvider');
  }

  return context as SteamhammerContext<Data>;
};

export const SteamhammerProvider = <Data extends Record<string, string>>({
  children,
  defaultOptions,
  getHeaders,
  localStorageId,
}: SteamhammerProviderProps<Data>) => {
  // client is set in state to handle being able to wait for the mock worker to start in development mode
  const [client, setClient] = useState<QueryClient | null>(null);

  useMemo(async () => {
    // Start the mocking conditionally
    /* istanbul ignore next */
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.USE_MOCK_API === 'true'
    ) {
      if (!process.env.API_BASE_URL) {
        throw new Error('missing ‘API_BASE_URL’ in .env file');
      }

      const { startMockWorker } = await import('../../mock-api/browser');

      startMockWorker(process.env.API_BASE_URL);
    }

    setClient(new QueryClient({ defaultOptions }));
  }, [defaultOptions]);

  // option persistent state which stores the value in localStorage if required
  // e.g. to store an Authorization token to get used in the getHeaders prop
  const { storage, setStorage } = useStorage<Data>(localStorageId || '');

  const SteamhammerContextProvider = SteamhammerContext.Provider as Context<
    SteamhammerContext<Data>
  >['Provider'];

  return client ? (
    <QueryClientProvider client={client}>
      <SteamhammerContextProvider
        value={{
          getHeaders: () => getHeaders(storage),
          storage,
          setStorage,
        }}
      >
        {children}
      </SteamhammerContextProvider>
      {/* react-query-devtools will only be bundled in development */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  ) : null;
};
