import { themeifyStyle, transparentize } from '../../themes/utilities';

export const variant = {
  primary: {},
  lightShadow: themeifyStyle(({ palette, spacing }) => ({
    boxShadow: `0 ${spacing.baseNeg7}px ${spacing.baseNeg4}px ${transparentize(
      palette.neutral500,
      25,
    )}`,
  })),
  darkShadow: themeifyStyle(({ palette, spacing }) => ({
    boxShadow: `0 ${spacing.baseNeg7}px ${spacing.baseNeg4}px ${transparentize(
      palette.neutral500,
      50,
    )}`,
  })),
};
