import { useMemo } from 'react';

import { ThemeTokens } from '../../themes/types';

import * as styles from './use-text.styles';

export interface UseTextStyleProps {
  transform?: keyof typeof styles.textTransform | false;
  truncate?: boolean;
  variant?: keyof ThemeTokens['typography']['variant'] | 'inherit';
  align?: keyof typeof styles.align;
}

/**
 * return either all text styles including variant,
 * or just the variant (useful in input fields)
 */
export const useTextStyles = ({
  align,
  transform = false,
  variant: variantName = 'body150',
  truncate = false,
}: UseTextStyleProps) => {
  // We encode objects to a string here so that useMemo can do
  // an equality check on the values, then decode inside useMemo.

  return useMemo(() => {
    const variant =
      variantName === 'inherit' ? styles.inherit : styles.variant(variantName);

    return {
      variant,
      styles: [
        styles.text,
        transform && styles.textTransform[transform],
        truncate && styles.truncate,
        variant,
        align && styles.align[align],
      ],
    };
  }, [variantName, transform, truncate, align]);
};
