import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LoadingSpinner } from '@shieldpay/bumblebee';
import { SteamhammerProvider } from '@shieldpay/steamhammer-ui';

import { OnboardingLayout } from './components/onboarding-layout/onboarding-layout';
import { appRoutes } from './routes';

const OnboardingStepsPage = lazy(() =>
  import('./pages/onboarding-steps').then((module) => ({
    default: module.OnboardingStepsPage,
  })),
);

const OnboardingSmsVerificationPage = lazy(() =>
  import('./pages/onboarding-sms-verification').then((module) => ({
    default: module.OnboardingSmsVerificationPage,
  })),
);

const OnboardingSuccessPage = lazy(() =>
  import('./pages/onboarding-success').then((module) => ({
    default: module.OnboardingSuccessPage,
  })),
);

const SmsResendLimitReachedPage = lazy(() =>
  import('./pages/errors/sms-resend-limit-reached').then((module) => ({
    default: module.SmsResendLimitReachedPage,
  })),
);

const LinkExpiredPage = lazy(() =>
  import('./pages/errors/link-expired').then((module) => ({
    default: module.LinkExpiredPage,
  })),
);

const InvalidCodeLimitPage = lazy(() =>
  import('./pages/errors/invalid-code-limit').then((module) => ({
    default: module.InvalidCodeLimitPage,
  })),
);

const DetailsAlreadySubmittedPage = lazy(() =>
  import('./pages/errors/details-already-submitted').then((module) => ({
    default: module.DetailsAlreadySubmittedPage,
  })),
);

const BlockedLinkPage = lazy(() =>
  import('./pages/errors/blocked-link').then((module) => ({
    default: module.BlockedLink,
  })),
);

export const ONBOARDING_STEAMHAMMER_KEY = 'spOnboardingStorage';

export const App = () => {
  return (
    <Suspense fallback={<LoadingSpinner size="xxlarge" center />}>
      <SteamhammerProvider
        localStorageId={ONBOARDING_STEAMHAMMER_KEY}
        getHeaders={(storage) => {
          if (storage?.token)
            return { Authorization: `Bearer ${storage.token}` };
        }}
      >
        <OnboardingLayout>
          <Routes>
            {/* TODO: Protect sms route so only accessible via link - possibly use the query string for that */}
            <Route
              path={appRoutes.root('*')}
              element={<OnboardingStepsPage />}
            />
            <Route
              path={appRoutes.smsVerification()}
              element={<OnboardingSmsVerificationPage />}
            />
            <Route
              path={appRoutes.success()}
              element={<OnboardingSuccessPage />}
            />
            <Route
              path={appRoutes.smsResendLimitReached()}
              element={<SmsResendLimitReachedPage />}
            />
            <Route
              path={appRoutes.linkExpired()}
              element={<LinkExpiredPage />}
            />
            <Route
              path={appRoutes.blockedLink()}
              element={<BlockedLinkPage />}
            />
            <Route
              path={appRoutes.detailsAlreadySubmitted()}
              element={<DetailsAlreadySubmittedPage />}
            />
            <Route
              path={appRoutes.invalidCodeLimit()}
              element={<InvalidCodeLimitPage />}
            />
            <Route path="*" element={<>{/* TODO - handle 404s etc */}</>} />
          </Routes>
        </OnboardingLayout>
      </SteamhammerProvider>
    </Suspense>
  );
};
