import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Grid } from '../grid/grid';
import { Text } from '../text/text';

interface OnboardingLayoutProps {
  footer: ReactNode;
  header: ReactNode;
  children: ReactNode;
}

export const OnboardingLayout = ({
  children,
  footer,
  header,
}: OnboardingLayoutProps) => (
  <Grid layout="onboarding" fullHeight>
    <Box
      Component="header"
      background={{ l: 'neutral500' }}
      color={{ xs: 'neutral500', l: 'neutral000' }}
      gridItem={{
        l: {
          rowStart: '1',
          rowEnd: 'span2',
        },
      }}
      padding={{
        xs: 'basePos4',
        l: 'basePos5',
        xl: { x: 'basePos7', y: 'basePos5' },
      }}
      alignContent={{ x: 'left' }}
    >
      {header}
    </Box>

    <Grid
      layout="onboardingForm"
      spacing="basePos4"
      gridItem={{ l: { rowStart: '2', rowEnd: 'span2' } }}
    >
      <Box
        Component="main"
        gridItem={{ columnStart: '2' }}
        padding={{ bottom: 'basePos8' }}
      >
        {children}
      </Box>
    </Grid>

    <Box
      Component="footer"
      padding={{
        xs: { bottom: 'basePos5', x: 'basePos5' },
        xl: { x: 'basePos7', y: 'basePos5' },
      }}
      background={{ l: 'neutral500' }}
      color={{ xs: 'neutral500', l: 'neutral000' }}
      gridItem={{ columnStart: '1', rowStart: '3' }}
      alignContent={{ x: 'center', y: 'bottom' }}
    >
      <Text color={{ xs: 'neutral500', l: 'neutral000' }} variant="caption150">
        {footer}
      </Text>
    </Box>
  </Grid>
);
