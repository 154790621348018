import { css } from '@emotion/react';

export const toastRack = () =>
  css({
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'column-reverse',
  });
