import { css, Theme } from '@emotion/react';

import { transformValues } from '@shieldpay/utility-functions-ui';

import { Breakpoint, ResponsiveProps, Spacing } from '../../themes/types';
import {
  createThemedStyleObjects,
  isResponsive,
  nestedCSSObjectToStyles,
  responsiveStyle,
  themeifyStyle,
} from '../../themes/utilities';

export type Palette = keyof Theme['palette'] | 'currentColor';

export type Color = Palette | ResponsiveProps<Palette>;

export const variant = nestedCSSObjectToStyles({
  browserDefault: {},
  flex: { display: 'flex' },
  grid: { display: 'grid' },
});

export const stack = nestedCSSObjectToStyles({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
});

export const stackXAlignment = {
  column: nestedCSSObjectToStyles({
    left: { alignItems: 'flex-start' },
    right: { alignItems: 'flex-end' },
    center: { alignItems: 'center' },
    full: { alignItems: 'stretch' },
  }),
  row: nestedCSSObjectToStyles({
    left: { justifyContent: 'flex-start' },
    right: { justifyContent: 'flex-end' },
    center: { justifyContent: 'center' },
    full: { justifyContent: 'space-between' },
  }),
};

export const stackYAlignment = {
  column: nestedCSSObjectToStyles({
    top: { justifyContent: 'flex-start' },
    bottom: { justifyContent: 'flex-end' },
    center: { justifyContent: 'center' },
    full: { justifyContent: 'space-between' },
  }),
  row: nestedCSSObjectToStyles({
    top: { alignItems: 'flex-start' },
    bottom: { alignItems: 'flex-end}' },
    center: { alignItems: 'center' },
    full: { alignItems: 'stretch' },
  }),
};

export const expand = css({
  alignSelf: 'stretch',
  flexGrow: 1,
});

/**
 * Returns a style map using spacing tokens as keys, with a gap property set
 * to the theme token value, e.g:
 *
 * {
 *  base: {
 *    gap: '16px'
 *  },
 *  basePos1: {
 *    gap: '20px'
 *  }
 *  // ...
 * }
 */
export const spacing = (token: Spacing) =>
  themeifyStyle(({ spacing }) => ({
    gap: `${spacing[token]}px`,
  }));

/**
 * Returns a style map for given padding property, keyed by size and using
 * spacing theme tokens e.g:
 *
 * {
 *  xsmall: {
 *    paddingLeft: '16px',
 *  },
 *  small: {
 *    paddingLeft: '20px'
 *  }
 * }
 */
const generatePaddingStyleMap = (
  paddingProperties: Array<
    'padding' | 'paddingLeft' | 'paddingRight' | 'paddingTop' | 'paddingBottom'
  >,
  { spacing }: Theme,
) =>
  transformValues(spacing, (pixels) =>
    Object.fromEntries(
      paddingProperties.map((property) => [[property], `${pixels}px`]),
    ),
  );

export const [padding, paddingResponsive] = createThemedStyleObjects((theme) =>
  generatePaddingStyleMap(['padding'], theme),
);

export const [paddingLeft, paddingLeftResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingLeft'], theme),
);
export const [paddingRight, paddingRightResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingRight'], theme),
);
export const [paddingTop, paddingTopResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingTop'], theme),
);
export const [paddingBottom, paddingBottomResponsive] =
  createThemedStyleObjects((theme) =>
    generatePaddingStyleMap(['paddingBottom'], theme),
  );

export const borderRadius = themeifyStyle(({ borderRadius }) => ({
  borderRadius,
}));

export const size =
  (
    size: Partial<
      Record<'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight', Spacing>
    >,
  ) =>
  ({ spacing }: Theme) =>
    [
      size.maxWidth && css({ maxWidth: `${spacing[size.maxWidth]}px` }),
      size.minWidth && css({ minWidth: `${spacing[size.minWidth]}px` }),
      size.minHeight && css({ minHeight: `${spacing[size.minHeight]}px` }),
      size.maxHeight && css({ maxHeight: `${spacing[size.maxHeight]}px` }),
    ];

export const touchArea = themeifyStyle((theme) => ({
  padding: `${theme.spacing.baseNeg4}px`,
  margin: `-${theme.spacing.baseNeg4}px`,
  cursor: 'pointer',
  '& > *': {
    cursor: 'pointer',
  },
}));

export const background = (color: Palette) =>
  themeifyStyle((theme) => colorTokenToCSSObject(theme, color, 'background'));

export const border = (color: Palette) =>
  themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${
      color === 'currentColor' ? 'currentColor' : palette[color]
    }`,
  }));

const colorTokenToCSSObject = (
  { palette }: Theme,
  color: Palette,
  key: string,
) => ({
  [key]: color === 'currentColor' ? 'currentColor' : palette[color],
});

/**
 * gets serialized styles from color props, optionally with responsive values
 */
export const colorPropStyles = (prop: Color, propName: string) =>
  themeifyStyle((theme) =>
    !isResponsive(prop)
      ? colorTokenToCSSObject(theme, prop, propName)
      : responsiveStyle(
          // responsiveStyle accepts an array in the shape of
          // [{breakpoint: 'xl', styles}]
          (Object.entries(prop) as Array<[Breakpoint, Palette]>).map(
            ([breakpoint, color]) => ({
              breakpoint,
              styles: colorTokenToCSSObject(theme, color, propName),
            }),
          ),
          theme,
        ),
  );

export const constrain = css({
  overflowX: 'clip',
  // TODO - good to always do this??
  textOverflow: 'ellipsis',
  // Suggest reviewing if we still need fallback end of 2023 onwards.
  '@supports not (overflow: clip)': {
    overflowX: 'hidden',
  },
});
