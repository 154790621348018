export const appRoutes = {
  bankAccountType: () => '/bank-account-type',
  bankDetailsManual: () => '/bank-details-manual',
  blockedLink: () => '/blocked-link',
  businessDetails: () => '/your-business-details',
  connectYourBank: () => '/connect-your-bank', // Page to show when DirectID opens in a new tab
  detailsAlreadySubmitted: () => '/details-already-submitted',
  invalidCodeLimit: () => '/invalid-code-limit',
  linkExpired: () => '/link-expired',
  reviewAndSubmit: () => '/review-details',
  root: (subPath: string) => `/${subPath}`,
  personalDetails: () => '/your-personal-details',
  provideBankDetails: () => '/provide-bank-details',
  smsResendLimitReached: () => '/sms-verification-resend-limit',
  smsVerification: () => '/sms-verification',
  startVerification: () => '/start-verification',
  success: () => '/success',
};
