import { useMemo, useState } from 'react';

export const useStorage = <Data extends Record<string, string>>(
  localStorageId: string,
) => {
  const [storage, setStorage] = useState(() =>
    globalThis.localStorage.getItem(localStorageId),
  );

  return useMemo(
    () => ({
      storage: storage ? (JSON.parse(storage) as Data) : undefined,
      setStorage: (data: Data) => {
        const serialised = JSON.stringify(data);
        globalThis.localStorage.setItem(localStorageId, serialised);
        setStorage(serialised);
      },
    }),
    [storage, localStorageId],
  );
};
