import { stringToDigitsOnly } from '@shieldpay/utility-functions-ui';

import { Breakpoint, ResponsiveProps } from '../../themes/types';
import {
  isResponsive,
  responsiveStyle,
  themeifyStyle,
} from '../../themes/utilities';

// Add more options here as required
type GridStart = '1' | '2' | '3';
type GridEnd = 'span2';

type GridItem = {
  columnStart?: GridStart;
  columnEnd?: GridEnd;
  rowStart?: GridStart;
  rowEnd?: GridEnd;
};

export type GridsItemProps = GridItem | ResponsiveProps<GridItem>;

// Simple function to convert values like `span2` to `span 2`
const propToValue = (prop: string) =>
  prop.startsWith('span') ? `span ${stringToDigitsOnly(prop)}` : prop;

/**
 * For providing custom positioning of child elements of the Grid component
 */
export const gridToCSSObject = ({
  columnStart,
  columnEnd,
  rowStart,
  rowEnd,
}: GridItem) =>
  Object.fromEntries(
    [
      columnStart && ['gridColumnStart', propToValue(columnStart)],
      columnEnd && ['gridColumnEnd', propToValue(columnEnd)],
      rowStart && ['gridRowStart', propToValue(rowStart)],
      rowEnd && ['gridRowEnd', propToValue(rowEnd)],
    ].filter(Boolean) as Array<[string, string]>,
  );

/**
 * gets serialized styles from color props, optionally with responsive values
 */
export const gridItemStyles = (prop: GridsItemProps) =>
  themeifyStyle((theme) =>
    !isResponsive(prop)
      ? gridToCSSObject(prop)
      : responsiveStyle(
          // responsiveStyle accepts an array in the shape of
          // [{breakpoint: 'xl', styles}]
          (Object.entries(prop) as Array<[Breakpoint, GridItem]>).map(
            ([breakpoint, gridItem]) => ({
              breakpoint,
              styles: gridToCSSObject(gridItem),
            }),
          ),
          theme,
        ),
  );
