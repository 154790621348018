import { Ref } from 'react';

import { Box, BoxProps } from '../box/box';

interface LayoutProps
  extends Pick<
    BoxProps,
    | 'alignContent'
    | 'spacing'
    | 'children'
    | 'stack'
    | 'padding'
    | 'expand'
    | 'className'
    | 'Component'
    | 'style'
    | 'id'
    | 'variant'
    | 'size'
    | 'constrain'
  > {
  forwardRef?: Ref<unknown>;
}

export const Layout = ({ forwardRef, ...props }: LayoutProps) => (
  <Box {...(props as BoxProps)} ref={forwardRef} />
);
