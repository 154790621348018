import { responsiveStyle, themeifyStyle } from '@shieldpay/bumblebee';

// TODO this whole approach needs a thorough reworking as part of a look at our layouts.
export const mobileLogoResponsiveVisibility = themeifyStyle((theme) =>
  responsiveStyle(
    // TODO breakpoint show/hide props
    [
      {
        breakpoint: 'l',
        styles: {
          display: 'none',
        },
      },
    ],
    theme,
  ),
);

export const logo = themeifyStyle((theme) =>
  responsiveStyle(
    // TODO breakpoint show/hide props
    [
      {
        breakpoint: 'xs',
        styles: {
          display: 'none',
        },
      },
      {
        breakpoint: 'l',
        styles: {
          display: 'block',
        },
      },
    ],
    theme,
  ),
);
