import { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Layout, OnboardingLayout as PageLayout } from '@shieldpay/bumblebee';

import { ShieldpayLogo } from '../logos/shieldpay-logo';

import * as styles from './onboarding-layout.styles';

interface OnboardingLayoutProps {
  children: ReactNode;
}

export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => (
  <PageLayout
    header={
      <>
        <ShieldpayLogo css={styles.mobileLogoResponsiveVisibility} />
        <ShieldpayLogo variant="white" css={styles.logo} />
      </>
    }
    footer={
      <FormattedMessage
        id="shieldpay.onboarding.footer"
        values={{ year: <FormattedDate value={Date.now()} year="numeric" /> }}
      />
    }
  >
    <Layout alignContent={{ x: 'full' }} spacing="basePos3">
      {children}
    </Layout>
  </PageLayout>
);
