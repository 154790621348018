import { Box, BoxProps } from '../box/box';

import { UseTextStyleProps, useTextStyles } from './use-text-styles';

type ParagraphOrComponent =
  | {
      Component: BoxProps['Component'];
      paragraph?: never;
    }
  | {
      Component?: never;
      paragraph?: boolean;
    };

export type TextProps = Pick<
  BoxProps,
  | 'children'
  | 'className'
  | 'hidden'
  | 'padding'
  | 'color'
  | 'htmlFor'
  | 'id'
  | 'expand'
> &
  UseTextStyleProps &
  ParagraphOrComponent;

export const Text = ({
  children,
  Component,
  variant,
  transform = false,
  hidden = false,
  paragraph = false,
  truncate = false,
  padding = 'none',
  align,
  color = 'neutral500',
  ...boxProps
}: TextProps) => (
  <Box
    variant="initial"
    Component={Component || (paragraph ? 'p' : 'span')}
    css={
      useTextStyles({
        variant,
        truncate,
        transform,
        align,
      }).styles
    }
    color={color}
    hidden={hidden}
    padding={padding}
    {...boxProps}
  >
    {children}
  </Box>
);
