import { ForwardedRef, forwardRef } from 'react';

import { Box, BoxProps } from '../box/box';

import * as styles from './card.styles';

export interface CardProps
  extends Pick<
    BoxProps,
    | 'padding'
    | 'spacing'
    | 'background'
    | 'stack'
    | 'className'
    | 'children'
    | 'Component'
    | 'method'
    | 'alignContent'
    | 'role'
    | 'htmlFor'
    | 'style'
    | 'expand'
    | 'constrain'
  > {
  boxVariant?: BoxProps['variant'];
  variant?: keyof typeof styles.variant;
  'data-testid'?: string;
}

export const TEST_IDS = {
  CARD: 'card-component',
};

/**
 * Do not use the CardWithoutForwardRef export in the platform, use the Card export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const CardWithoutForwardRef = <Element,>(
  {
    variant = 'primary',
    boxVariant = 'flex',
    padding = 'basePos4',
    'data-testid': testId = TEST_IDS.CARD,
    background = 'neutral000',
    className,
    ...props
  }: CardProps,
  ref: ForwardedRef<Element>,
) => (
  <Box
    className={className}
    {...({
      ...props,
      variant: boxVariant,
      border: {
        primary: 'neutral200',
        lightShadow: 'neutral350',
        darkShadow: undefined,
      }[variant],
      // workaround to silence type error
      // it ain’t pretty.
    } as BoxProps)}
    background={background}
    css={styles.variant[variant]}
    data-testid={testId}
    padding={padding}
    ref={ref}
    rounded
  />
);

// Manually reset the displayName in the component tree.
CardWithoutForwardRef.displayName = 'Card';

export const Card = forwardRef(CardWithoutForwardRef);
