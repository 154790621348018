import {
  AllHTMLAttributes,
  CSSProperties,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import { SpringValue } from 'react-spring';

import { UseBoxStyleProps, useBoxStyles } from './use-box-styles';

export type BoxProps = UseBoxStyleProps &
  Pick<
    AllHTMLAttributes<HTMLElement>,
    /** carefully add any html attributes we might use here when needed */
    | 'disabled'
    | 'onClick'
    | 'onChange'
    | 'onSubmit'
    | 'htmlFor'
    | 'id'
    | 'method'
    | 'name'
    | 'role'
    | 'className'
    | 'hidden'
    | 'checked'
    | 'type'
    | 'value'
    | 'title'
    | 'autoComplete'
    | 'type'
  > & {
    Component?: ElementType;
    style?: Record<string, SpringValue> | CSSProperties;
    children?: ReactNode;
    /** special case to be used for NavLink components */
    to?: string;
  };

/**
 * Do not use the BoxWithoutForwardRef export in the platform, use the Box export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const BoxWithoutForwardRef = <T,>(
  {
    Component = 'div',
    background,
    color,
    children,
    stack,
    variant = 'flex',
    spacing,
    padding,
    alignContent,
    touchArea,
    rounded,
    expand,
    style, //this is meant to be used by react-spring for animations, not general styling.
    size,
    constrain,
    border,
    gridItem,
    ...restProps
  }: BoxProps,
  ref: ForwardedRef<T>,
) => {
  const css = useBoxStyles({
    background,
    border,
    color,
    stack,
    variant,
    padding,
    rounded,
    touchArea,
    expand,
    alignContent,
    spacing,
    size,
    constrain,
    gridItem,
  } as UseBoxStyleProps);

  return (
    <Component css={css} style={style} {...restProps} ref={ref}>
      {children}
    </Component>
  );
};

// Manually reset the displayName in the component tree.
BoxWithoutForwardRef.displayName = 'Box';

export const Box = forwardRef(BoxWithoutForwardRef);
